//====================================================
//  Initialization Remove Product
//====================================================

function fn_parts_remove_product(){
	if ($('.js-remove-product-trigger').length){
		$(document).on('click', '.js-remove-product-trigger',  function(e){
			var productId = $(this).closest('.js-remove-product-block').data('id');
			var productBlock = $('.js-product-basket');

	        $('.js-remove-product-block[data-id="' + productId + '"]').remove();
	        
	        var productCount = productBlock.find( '.js-remove-product-block' ).length;
	        
	        // $('.js-product-basket-value').text(productCount);

	        if ( productCount == 0 ){
	        	$('.js-product-basket').addClass('is-empty');
	        }

	        var resultPrice = 0;

			$(".js-basket-list .basket-list-item").each(function () {
				var inst = $(this);
				var productPrice = parseFloat(inst.data( 'price' ));
				resultPrice = resultPrice + productPrice;
		    });


	        $('.js-basket-price').text(resultPrice.toFixed(2));

	        e.preventDefault();
	    });

	}
}