/* ==========================================================================
 *  Function: Tooltip
 * ======================================================================= */
function fn_components_tooltip(){
	if ($( '.js-tooltip' ).length){
		tippy('.js-tooltip', {
			placement: 'top',
			animation: 'shift-away',
			// trigger: 'click',
			arrow: true
		})
	}
}