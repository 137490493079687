//====================================================
//  Function: Modal
//====================================================
function fn_components_modal(){
	if ($( '.js-modal-trigger' ).length){
		// open modal 
		$( '.js-modal-trigger' ).on( 'click' , function(){
			var inst = $(this);
			var modalId = inst.data( 'modal' );
			$( '.modal-block.active' ).removeClass( 'active' );
			$( '.modal-overlay' ).addClass( 'active' );
			$( '.modal-block[data-modal=' + modalId + ']' ).addClass( 'active' );
			return false;
		});

		// close modal 
		$( '.js-close-modal' ).on( 'click' , function(){
			$( '.modal-overlay' ).removeClass( 'active' );
			$( '.modal-block.active' ).removeClass( 'active' );
			return false;
		});

		// close modal keypress key escape
		$(document).keydown(function(event) { 
			if (event.keyCode == 27 && $( '.modal-block' ).hasClass( 'active' )) { 
				$( '.js-close-modal' ).trigger( 'click' );
			}
		});


	}
}