// --- LAYOUT, COMPONENTS, PARTS --- //
$(function(){
	// ---  LAYOUT --- //
	//fn_layout_main();

	// ---  COMPONENTS --- //
	fn_components_ajaxLoad();
	fn_components_gallery();
	fn_components_fullscreen_navigation();
	fn_components_slider();
	fn_components_wow();
	fn_components_tabs();

	fn_parts_show_hidden();
});


// --- MISC --- //
$(function () {
	nl_fn_scroll_to();
});

$(document).scroll(function () {
});

$(window).resize(function(){
	fn_checkNavigationTrigger();
});

$(window).bind('load', function () {
	fn_parts_dots();
});

