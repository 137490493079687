/* ==========================================================================
 *  Function: Dropdown
 * ======================================================================= */
function fn_parts_dropdown(){
	if ($( '.js-dropdown-trigger' ).length && $(window).width() < 768){
		$( '.js-dropdown-trigger' ).on( 'click' , function () {
			$(this).closest( '.js-dropdown-block' ).toggleClass( 'active' );
			if ($(this).closest( '.js-dropdown-block.basket-group' ).hasClass('active')){
	        	$( '.navigation.nav-active' ).removeClass( 'nav-active' );
			}
		});
	}
}