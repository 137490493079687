//====================================================
//  Function: Result box
//====================================================
function fn_components_result_box(){
	if ($( '.js-select-set-top-box' ).length){
		$( '.js-select-set-top-box' ).bind( 'click' , function() {
			$('#result-box').find( '.js-hidden-content[data-content="result-set-box"]' ).addClass( 'active' );
			$('#result-box').find( '.js-hidden-content[data-content="result-main"]' ).removeClass( 'active' );
			scrollTopResultBox();
		});
	}

	if ($( '.js-send-mail' ).length){
		$( '.js-send-mail' ).bind( 'click' , function() {
			$('#result-box').find( '.js-hidden-content[data-content="result-email"]' ).addClass( 'active' );
			$('#result-box').find( '.js-hidden-content[data-content="result-main"]' ).removeClass( 'active' );
			scrollTopResultBox();
		});
	}

	if ($( '.box-close-icon' ).length){
		$( '.box-close-icon' ).bind( 'click' , function() {
			$('#result-box').find( '.js-hidden-content.active' ).removeClass( 'active' );
			$('#result-box').find( '.js-hidden-content[data-content="result-main"]' ).addClass( 'active' );
			scrollTopResultBox();
		});
	}

	if ($( '.js-select-offer' ).length){
		$( '.js-select-offer' ).bind( 'click' , function() {
			var value = $(this).data( 'text' );
			$( '.js-select-set-top-box' ).val( value );
			$('#result-box').find( '.js-hidden-content[data-content="result-set-box"]' ).removeClass( 'active' );
			$('#result-box').find( '.js-hidden-content[data-content="result-main"]' ).addClass( 'active' );
			scrollTopResultBox();
		});
	}

	function scrollTopResultBox(){
		if ($(window).width() < 992){
			if ($(window).width() > 768) var height = 110
			else var height = 70

			$('html, body').stop().animate({
	            scrollTop: $( '#result-box' ).offset().top - height
	        });
		}
	}

	if ($( '.checkbox-group input' ).length){
		$( '.checkbox-group input' ).change( function() {
			calculateResultPrice();
		});
		
		function calculateResultPrice(){

			var baseText = $( '.js-result-offer' ).data( 'default' );
			var basePrice = $( '.js-result-price' ).data( 'default' );

			var resultText = [baseText];
			var resultPrice = parseFloat(basePrice);


			console.log(resultPrice);

			$(".checkbox-group input:checked").each(function () {
				var inst = $(this);
				// var productPrice = parseFloat($(this).closest('.js-select-product').data( 'price' ));
				var productPrice = parseFloat($(this).closest('.js-select-product').data( 'price' ));
				var productName = $(this).closest('.js-select-product').data( 'name' );
				resultPrice = resultPrice + productPrice;
				resultText.push(productName);
		    });

		    $( '.js-result-price' ).text(resultPrice.toFixed(2));
		    $( '.js-result-offer' ).text(resultText.join(' + '));
		}
	}

}
