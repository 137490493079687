/* ==========================================================================
 *  Function: Ajax load
 * ======================================================================= */
function fn_components_ajaxLoad(){
    if ($( '.js-load-more-content' ).length){
        $( '.js-load-more-content' ).bind('click', function(){
            var thisGrid = $(this).closest( '.js-load-more-block' );
            var thisContent = $(this).data('content');
            $.ajax({
                url: 'public/js/data/' + thisContent + '.html',
                data: 'html',
                beforeSend:function(){
                    thisGrid.find( '.js-load-more' ).addClass( 'disabled' );
                    thisGrid.find( '.js-load-more .loading-post' ).addClass( 'active' );
                },
                success:function(data){
                    setTimeout(function(){ 
                        thisGrid.find( '.js-load-more-grid' ).append(data);
                        thisGrid.find( '.js-load-more' ).removeClass( 'disabled' )
                        thisGrid.find( '.js-load-more .loading-post' ).removeClass( 'active' );
                        fn_parts_dots();
                        thisGrid.addClass( 'last' );
                    }, 2000);
                },
                error:function(){
                    console.log( 'error' );
                }
            });
        });
    }
}
