//====================================================
//  Initialization Slider
//====================================================
function fn_components_slider(){
	fn_main_slider();
}


function fn_main_slider(){
	if ($( '.js-slider-main' ).length){
		$( '.js-slider-main' ).slick({
			infinite: true,
			dots: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			autoplay: true,
			autoplaySpeed: 2500,
			cssEase: 'linear',
			speed: 500,
			fade: true,
			variableWidth: false,
			mobileFirst: true
		});
	}
}
