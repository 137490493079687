//====================================================
//  Function: Tabs
//====================================================
function fn_components_tabs(){
	if ($( '.js-tabs-group' ).length){
		$( '.js-tabs-group .js-tabs-list-item' ).click(function() {
			var inst = $(this);
			var thisId = inst.attr( 'id' );
			var thisTabs = $(this).closest( '.js-tabs-group' );

			if(thisId == 'undefined'){
				return false;
			}
			
			if (!inst.hasClass( 'active' )){
				thisTabs.find( '.js-tabs-list-item.active' ).removeClass( 'active' );
				$(this).closest( '.js-tabs-list-item').addClass( 'active' );
				thisTabs.find( '.js-tabs-content .js-tabs-content-item.active' ).removeClass( 'active' );
				thisTabs.find( '.js-tabs-content .js-tabs-content-item[data-id="'+ thisId + '"]' ).addClass( 'active' );
			}
		});
	}
}
