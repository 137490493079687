//====================================================
//  Function: Show/hidden
//====================================================
function fn_parts_show_hidden(){
	if ($( '.js-active-class-toggle-trigger' ).length){
		$( '.js-active-class-toggle-trigger' ).bind( 'click' , function() {
			$(this).closest( '.js-active-block' ).find( '.js-hidden-content' ).toggleClass( 'active' );
		});
	}

	if ($( '.js-active-class-trigger' ).length){
		$( '.js-active-class-trigger' ).bind( 'click' , function() {
			$(this).closest( '.js-active-block' ).find( '.js-hidden-content' ).addClass( 'active' );
		});
	}

	if ($( '.js-remove-class-trigger' ).length){
		$( '.js-remove-class-trigger' ).bind( 'click' , function() {
			$(this).closest( '.js-remove-block' ).find( '.js-hidden-content' ).removeClass( 'active' );
		});
	}

}
