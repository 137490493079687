//====================================================
//  Function: Submit form
//====================================================
function fn_components_submit_form(){
	if ($( '.js-submit-form' ).length){
		$( '.js-submit-form' ).bind( 'click' , function() {
			var inst = $(this);
			var dataContent = inst.attr('data-content');
			var thisBlock = inst.closest( '.js-hidden-block' );
			thisBlock.find( '.js-hidden-content.active' ).removeClass('active');
			thisBlock.find( '.js-hidden-content[data-content=' + dataContent + ']' ).addClass('active');

			return false;
		});
	}
}
